import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function CaptionCardWrapper({ className, children }) {
  return (
    <div
      className={className}
      css={css`
        position: relative;
      `}
    >
      {children}
    </div>
  );
}
